let callbackModal = document.querySelector('#doneModalCallback');
let callbackSentModal = document.querySelector('#doneModalCallbackSent');

window.showCallback = function showCallback() {
  callbackModal.showModal();
};

window.closeCallback = function closeCallback() {
  callbackModal.close();
};

window.showCallbackSent = function showCallbackSent() {
  callbackSentModal.showModal();
};

window.closeCallbackSent = function closeCallbackSent() {
  callbackSentModal.close();
};

let hash =
  window.location && window.location.hash && window.location.hash.substring(1);
if (hash.includes('greetings')) {
  setTimeout(() => {
    window.alert('Un conseiller vous rappellera dans les plus brefs délais :)');
  }, 300);
}
if (hash.includes('callback')) {
  setTimeout(() => {
    showCallback();
  }, 300);
}

let utm = window.location && window.location.search;
if (utm) {
  let date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `utmCampaign=${utm}; ${expires}; path=/`;
}
